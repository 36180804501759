<template>
    <v-container>
        <v-row justify="center" align="center">
            <v-col cols="3">
                <form @submit="onSubmit">
                    <v-card class="mt-20">
                        <v-card-title class="justify-center">
                            <v-img max-height="80" max-width="80" src="/img/logo.png" /><br>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text v-if="invitation">
                            <div class="group">
                            	  <br>
                                {{ invitation.message }}
                                <br><br>
                            </div>
                            <div class="register" v-if="!this.loggedIn">
                            		<br>
	                            	<v-text-field
											outlined
											label="Email"
											type="text"
											v-model="credentials.email"
											ref="email"
											:rules="[() => !!credentials.email || 'This field is required']"
											disabled
											></v-text-field>
											<v-text-field
											outlined
											label="Name"
											type="text"
											v-model="credentials.name"
											ref="name"
											:rules="[() => !!credentials.name || 'This field is required']"
											></v-text-field>
											<v-text-field
											outlined
											label="Password"
											type="password"
											v-model="credentials.password"
											ref="password"
											:rules="[() => !!credentials.password || 'This field is required']"
											></v-text-field>
											<v-text-field
											outlined
											label="Password Confirmation"
											type="password"
											v-model="credentials.passwordConfirm"
											ref="passwordConfirm"
											:rules="[() => !!credentials.passwordConfirm || 'This field is required']"
											></v-text-field>
										</div>
                        </v-card-text>
                        <v-card-text  v-if="error">
                            <div class="text-center">
                            	<div class="color-red">
                                {{ error }}
                                </div>
                            </div>
                        </v-card-text>
                        <v-card-text v-if="!invitation && !error">
                        	<div class="text-center">
                        	<v-progress-circular indeterminate color="primary"></v-progress-circular>
                        	</div>
                        </v-card-text>
                        <v-card-actions v-if="!error && this.loggedIn">
                        		<!-- Logged In -->
                            	<v-btn :disabled="!invitation" color="primary" type="submit" block>Join as {{ this.getUser.name }}</v-btn>
                        </v-card-actions>
                        <p  v-if="!error && !this.loggedIn" class="text-center">Already have a Chartarium account? <router-link to="/login">Login</router-link></p>
                        <v-card-actions v-if="!error && !this.loggedIn">
                        		<!-- Register and Join -->
                            	<v-btn :disabled="!invitation" color="primary" type="submit" block>Register and join</v-btn>
                        </v-card-actions>
                    </v-card>
                </form>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped></style>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "Login",
    data: () => ({
        invitation: null,
        error: null,
        valid: true,
        loading: false,
        credentials: {
            email: "",
            name: "asd",
            password: "asd",
            passwordConfirm: "fsdfs",
        },
    }),
    computed: mapGetters(["loggedIn", "getUser"]),
    mounted() {
        this.getInvitation();
    },
    methods: {
        ...mapActions(["login", "user"]),
        async getInvitation() {
        		let token = this.$route.params.token;
            try {
               let response = await this.$axios.get('/invitations/' + token);
               this.invitation = response.data;
               this.credentials.email = this.invitation.email;
            } catch (error) {
            	if(error.response) {
            		this.error = error.response.data.message;
            	}
            }
        },
        async onSubmit(e) {

            e.preventDefault();

            if(this.loggedIn) {
            	await this.join(e);
            } else {
            	await this.registerAndJoin(e);
            }

        },
        async registerAndJoin(e) {

        		e.preventDefault();

        		this.loading = true;
            this.valid = true;
            var self = this;

            Object.keys(this.credentials).forEach((item) => {
                let fieldValid = this.$refs[item].validate(true);
                // Unvalidate form if any fields are not valid
                if (!fieldValid) {
                    this.valid = false;
                }
            });
            if (!this.valid) {
                this.loading = false;
                return;
            }

            try {


               let response = await this.$axios.post('/auth/registerAndJoin');
               console.log(response)

	            /*let response = await this.$axios.post('/auth/registerAndJoin', {
	            	a: 'test',
	            });

	            console.log(response);*/
            } catch (error) {
            	if(error.response) {
            		console.log(error.response.data); 
            	} else {
            		console.log(error);
            	}
            }


        },
        async join() {

            try {
                let response = await this.$axios.post('/account/join', { token: this.$route.params.token});
                this.$router.push('/main/' + response.data.hash_id);
            } catch (error) {
                console.log(error);
            }

        }
    },
};
</script>